import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import PostList from "../components/postList"
import { Heading } from "../components/atoms"

const Blog = () => {
  return (
    <Layout activePage="blog">
      <SEO title="Blog" />
      <Heading>Blog</Heading>
      <p className="-mt-3 mb-12 text-tertiary">
        I primarily write about software development and technology, but
        sometimes I'll include other things that interest me.
      </p>
      <PostList />
    </Layout>
  )
}

export default Blog
